import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/',
    component: () => import('@/components/Home.vue'),
    meta: { title: 'Home' },
    children: [
      {
        path: 'task-management',
        name: 'TaskManagement',
        component: () => import('@/views/TaskManagement/index.vue'),
        meta: { title: '任务管理', icon: 'el-icon-tickets' },
      },
      {
        path: 'activation-code-management',
        name: 'ActivationCodeManagement',
        component: () => import('@/views/ActivationCodeManagement/index.vue'),
        meta: { title: '激活码管理', icon: 'el-icon-key' },
      },
      {
        path: 'user-management',
        name: 'UserManagement',
        component: () => import('@/views/UserManagement/index.vue'),
        meta: { title: '用户管理', icon: 'el-icon-user' },
      },
      {
        path: 'announcement-management',
        name: 'AnnouncementManagement',
        component: () => import('@/views/AnnouncementManagement/index.vue'),
        meta: { title: '公告管理', icon: 'el-icon-bell' },
      },
      {
        path: 'slideshow-management',
        name: 'SlideshowManagement',
        component: () => import('@/views/SlideshowManagement/index.vue'),
        meta: { title: '轮播图管理', icon: 'el-icon-picture' },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
