import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false, // 全局loading
    userInfo: {}
  },

  mutations: {
    // 清除所有vuex
    CLEAR_STATE(state) {
      state.isLoading = false;
      state.userInfo = {}
    },

    // 全局loading
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },

    // 存储个人信息
    SET_UESERINFO(state, userInfo) {
      state.userInfo = userInfo;
    }
  },

  actions: {
    // 开启全局loading
    showLoading({ commit }) {
      commit('SET_LOADING', true);
    },

    // 关闭全局loading
    hideLoading({ commit }) {
      commit('SET_LOADING', false);
    },
  },

  plugins: [createPersistedState()],
});
